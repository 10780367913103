import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { initializeApp } from 'firebase/app'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"

import App from './App.vue'
import router from './router'

const firebaseConfig = {

  apiKey: "AIzaSyAJYPz3zBR1UixlNnpi7_40liSUgRVeVLA",

  authDomain: "cfb-tradition.firebaseapp.com",

  projectId: "cfb-tradition",

  storageBucket: "cfb-tradition.appspot.com",

  messagingSenderId: "950687833659",

  appId: "1:950687833659:web:2ee7a454133483a7a6061e",

  measurementId: "G-DC1EQ7X3WJ"

};

initializeApp(firebaseConfig);
const pinia = createPinia()

createApp(App).use(router).use(pinia).mount('#app')
