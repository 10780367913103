import { createRouter, createWebHashHistory } from 'vue-router'

import { useAuthStore } from '@/stores/auth.store'

const routes = [
  {
    path: '/',
    name: 'Home Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/HomePage.vue')
  },
  {
    path: '/seasonpicks/:season',
    name: 'Season Picks',
    component: () => import(/* webpackChunkName: "seasonal-picks" */ '../views/SeasonPicks.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login Page',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
  },
  {
    path: '/compare',
    name: 'Compare Picks',
    component: () => import (/* webpackChunkName: "compare-picks" */ '../views/ComparisonPage.vue')
  },
  {
    path: "/:catchAll(.*)",
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.access) {
    auth.returnUrl = to.fullPath;
    return '/login';
  }
})
