<template>
  <body class="bg-light vh-100">
    <div>
      <NavBar />
      <router-view />
    </div>
  </body>
</template>

<script>
  import NavBar from "@/components/NavBar.vue";

  export default {
    components: {
      NavBar,
    },
  };
</script>