import router from '@/router';
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers/fetch-wrapper';

const baseUrl = `https://football-api.colefamily.info/db/api`;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    access: JSON.parse(localStorage.getItem('access')),
    refresh: JSON.parse(localStorage.getItem('refresh')),
  }),

  actions: {
    async login(username, password) {
      const token = await fetchWrapper.post(`${baseUrl}/token/`, { username, password });

      // update pinia state
      this.access = token.access;
      this.refresh = token.refresh;

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('access', JSON.stringify(token.access));
      localStorage.setItem('refresh', JSON.stringify(token.refresh));

      // redirect to previous url or default to home page
      router.push(this.returnUrl || '/');
    },
    logout() {
      this.token = null;
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      router.push('/login');
    }
  }
})