<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light mb-2 mb-lg-3 shadow-sm" style="background-color: #e3f2fd;">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
        aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="#">CFB Tradition</a>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{ name: 'Home Page' }" class="nav-link">Home Page
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Season Picks', params: { season: 2021 } }" class="nav-link">Season Picks
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Compare Picks' }" class="nav-link">Compare Picks
            </router-link>
          </li>
        </ul>
        <div class="d-flex mb-2 mb-lg-0">
          <div v-if="isLoggedIn" class="nav-item">
            <button @click="authStore.logout()" class="btn btn-outline-secondary me-2"><i class=" bi bi-box-arrow-in-right"></i> Logout</button>
          </div>
          <div v-else class="nav-item">
            <router-link :to="{ name: 'Login Page' }" class="nav-link">
              <button class="btn btn-outline-secondary me-2">
                <i class="bi bi-box-arrow-right"></i> Login
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
  import { computed } from 'vue';

  import { useAuthStore } from '@/stores/auth.store';

  const authStore = useAuthStore();

  const isLoggedIn = computed(() => authStore.access != null);
</script>